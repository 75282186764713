<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card title="Reporte de notas de credito y debito">
      <div class="row mb-1">
        <div class="col d-flex justify-content-between">
          <div>
            <b-button
              variant="success"
              class="btn-icon mr-1"
              @click="getReportNotes"
            >
              <feather-icon icon="RefreshCwIcon" size="16" />
            </b-button>
            <date-picker
              range-separator="-"
              v-model="rangePicker"
              :default-value="rangePicker"
              range
              append-to-body
              lang="es"
              format="YYYY-MM-DD"
              style="width: 200px"
              class="mr-1"
            ></date-picker>

            <b-form-input
              v-model="term"
              class="d-inline-block mr-1"
              style="width: 300px"
              placeholder="Buscar..."
            />
          </div>

          <download-excel
            class="btn btn-primary"
            :data="json_data"
            name="reporte_facturas.xls"
            :title="title_report"
            :fetch="downloadExcel"
          >
            <i class="fa fa-file-excel mr-1"></i>
            Exportar Excel
          </download-excel>
        </div>
      </div>

      <hr />
      <h5>Notas de credito</h5>
      <div class="row pb-1 pt-1 text-center">
        <div class="col">
          <div class="font-weight-bold">{{ quantity_ncs }}</div>
          <div class="text-primary">Cantidad</div>
        </div>
        <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(subtotal_ncs) }}
          </div>
          <div class="text-primary">Subtotal Ventas</div>
        </div>
        <!-- <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(discount_ncs) }}
          </div>
          <div class="text-primary">Descuentos</div>
        </div> -->
        <div class="col">
          <div class="font-weight-bold">{{ currencyFormat(tax_ncs) }}</div>
          <div class="text-primary">IVA</div>
        </div>
        <!-- <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(iva_devuelto_ncs) }}
          </div>
          <div class="text-primary">IVA Devuelto</div>
        </div> -->
        <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(subtotal_ncs - discount_ncs + tax_ncs) }}
          </div>
          <div class="text-primary">Total Neto</div>
        </div>
      </div>
      <h5>Notas de debito</h5>

      <div class="row pb-3 text-center">
        <div class="col">
          <div class="font-weight-bold">{{ quantity_nds }}</div>
          <div class="text-primary">Cantidad</div>
        </div>
        <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(subtotal_nds) }}
          </div>
          <div class="text-primary">Subtotal Ventas</div>
        </div>
        <!-- <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(discount_nds) }}
          </div>
          <div class="text-primary">Descuentos</div>
        </div> -->
        <div class="col">
          <div class="font-weight-bold">{{ currencyFormat(tax_nds) }}</div>
          <div class="text-primary">IVA</div>
        </div>
        <!-- <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(iva_devuelto_nds) }}
          </div>
          <div class="text-primary">IVA Devuelto</div>
        </div> -->
        <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(subtotal_nds - discount_nds + tax_nds) }}
          </div>
          <div class="text-primary">Total Neto</div>
        </div>
      </div>

      <b-table :items="report" :fields="tableColumns" striped responsive>
        <template #cell(created_at)="data">
          {{ dateFormat(data.item.created_at) }}
        </template>
        <template #cell(type)="data">
          {{ data.item.type.toUpperCase() }}
        </template>

        <template #cell(documentable)="data">
          {{ on_document(data.item.documentable_type) }}
          {{ getDocumentNotePertenece(data.item.documentable.key50digits) }}
        </template>

        <template #cell(subtotal)="data">
          {{ currencyFormat(data.item.subtotal) }}
        </template>
        <template #cell(tax)="data">
          {{ currencyFormat(data.item.tax) }}
        </template>
        <template #cell(total)="data">
          {{ currencyFormat(data.item.total) }}
        </template>
      </b-table>

      <p class="text-center" v-if="!report.length">
        No hay depositos realizados en este rango de fechas
      </p>
    </b-card>
  </b-overlay>
</template>

<script>
import ReportService from "@/services/report.service";
import DatePicker from "vue2-datepicker";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";

import { currencyFormat } from "@/helpers";

import {
  BOverlay,
  BCard,
  BButton,
  BTable,
  BBadge,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BFormInput
} from "bootstrap-vue";

export default {
  components: {
    VuePerfectScrollbar,
    BOverlay,
    BCard,
    DatePicker,
    BButton,
    BTable,
    BBadge,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BFormInput
  },
  data() {
    return {
      term: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 10
      },
      rangePicker: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ],
      report: [],
      loading: false,
      tableColumns: [
        { key: "reference", label: "Consecutivo" },
        { key: "type", label: "Tipo" },
        { key: "documentable", label: "Pertenece a" },
        { key: "client.full_name", label: "Cliente" },
        { key: "client.identification_number", label: "Cédula cliente" },
        { key: "created_at", label: "Fecha" },
        { key: "subtotal", label: "Subtotal" },
        // { key: "discount", label: "Descuento" },
        { key: "tax", label: "IVA" },
        { key: "total", label: "Total" }
      ],
      json_data: [],
      loading_excel: false,
      subtotal_ncs: 0,
      tax_ncs: 0,
      discount_ncs: 0,
      iva_devuelto_ncs: 0,
      quantity_ncs: 0,
      quantity_nds: 0,
      subtotal_nds: 0,
      tax_nds: 0,
      discount_nds: 0,
      iva_devuelto_nds: 0,
      checkedDefaultOptions: ["rechazado"],
      title_report: ""
    };
  },
  watch: {
    rangePicker() {
      if (this.rangePicker.length > 1) {
        this.getReportNotes();
      }
    },
    term(newVal) {
      this.getReportNotes();
    }
  },
  methods: {
    async getReportNotes(page = 1) {
      try {
        this.loading = true;
        const { data: res } = await ReportService.getReportNotes(page, {
          rangeDates: `${moment(this.rangePicker[0]).format(
            "yyyy-MM-DD"
          )},${moment(this.rangePicker[1]).format("yyyy-MM-DD")}`,
          term: this.term
        });
        this.report = res.documents.data;

        //this.pageCount = res.data.documents.last_page;
        //this.page = res.data.documents.current_page;
        this.items = res.documents.data;
        this.subtotal_ncs = res.subtotal_ncs;
        this.tax_ncs = res.tax_ncs;
        this.discount_ncs = res.discount_ncs;
        this.iva_devuelto_ncs = res.iva_devuelto_ncs;
        this.subtotal_nds = res.subtotal_nds;
        this.tax_nds = res.tax_nds;
        this.discount_nds = res.discount_nds;
        this.iva_devuelto_nds = res.iva_devuelto_nds;
        this.quantity_ncs = res.quantity_ncs;
        this.quantity_nds = res.quantity_nds;

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    currencyFormat(amount) {
      return currencyFormat(amount);
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-yyyy");
    },

    async downloadExcel() {
      let search = {
        rangeDates: `${moment(this.rangePicker[0]).format(
          "yyyy-MM-DD"
        )},${moment(this.rangePicker[1]).format("yyyy-MM-DD")}`,
        term: this.term
      };
      let exist_registers = true;
      this.skip = 0;
      this.json_data = [];
      this.loading_excel = true;

      this.title_report = [
        `Tavo Mensajería`,
        "Reporte de notas",
        `Fechas: desde ${moment(this.rangePicker[0]).format(
          "DD-MM-YYYY"
        )} hasta ${moment(this.rangePicker[1]).format("DD-MM-YYYY")}`
      ];

      try {
        while (exist_registers) {
          search.skip = this.skip;
          let report_data = await this.getSkipRegisters(1, search);
          let items = report_data;
          if (items.length > 0) {
            this.skip = this.skip + 100;
          } else {
            exist_registers = false;
          }
        }
        this.loading_excel = false;
        return this.json_data;
      } catch (error) {
        this.loading_excel = false;
        console.log(error);
      }
    },
    async getSkipRegisters(page, search) {
      try {
        let datos = await ReportService.getReportNotesSkip(page, search);
        let items = datos.data;
        items.forEach(i => {
          let el = {
            consecutivo: `${i.reference}`,
            tipo: i.type.toUpperCase(),
            "pertenece a": i.documentable
              ? `${this.on_document(i.documentable_type)} ${
                  i.documentable.reference
                }`
              : `${this.on_document(
                  i.documentable_type
                )} ${this.getDocumentNotePertenece(
                  i.documentable.key50digits
                )}`,
            cliente: i.client.name,
            "cedula cliente": i.client.identification_number,
            fecha: moment(i.created_at).format("DD-MM-YYYY"),
            subtotal: i.subtotal,
            descuento: i.discount,
            IVA: i.tax,
            total: i.total
          };
          this.json_data.push(el);
        });
        return items;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    getDocumentNotePertenece(clave) {
      return clave.substring(31, 41);
    },
    on_document(type) {
      let on_document = "FEC-";
      if (type === "App\\Invoice") {
        on_document = "FE-";
      } else if (type === "App\\Ticket") {
        on_document = "TE-";
      }
      return on_document;
    }
  },
  created() {
    this.getReportNotes();
  }
};
</script>
